import {HierarchyObject} from '@core/bo/hierarchy-tree.model';
import {Account} from '@core/bo/account.model';
import {Location} from '@core/bo/location.model';

export class OrganizationBase extends HierarchyObject {
    name: string;
    code: string;
    path: string;
    domain: string;
    allName: string;
    leaderId: number;
    leader: Account;
    hrSupportId: number;
    hrSupport: Account;
    welcomeTemplateId: number;
    status: number;
    isTrash: boolean;
    createdBy: number;
    createdDate: Date;
    modifiedBy: number;
    modifiedDate: Date;
    locationId: number;
    idErp: number;
}

export class Organization extends OrganizationBase {
    static STATUS_ACTIVE = 1;
    checked = false;
}
