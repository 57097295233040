export class HierarchyObject {
    id: number;
    parentId: number;
}

export class HierarchyTree<T extends HierarchyObject> {
    object: T;
    level: number;
    children: T[];
}
