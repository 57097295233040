export class AccountEmailBase {
    id: number;
    accountId: number;
    email: string;
    emailCheck: string;
    isAlias: boolean;
    createdBy: number;
    createdDate: Date;
    modifiedBy: number;
    modifiedDate: Date;
}

export class AccountEmail extends AccountEmailBase {
}
