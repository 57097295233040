import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {AccountEvent, Event, Pagination} from '@core/bo';
import {environment} from '@environments/environment';

@Injectable({providedIn: 'root'})
export class EventService {
    public url = environment.apiUrl.account + '/event';

    constructor(public http: HttpClient) {
    }

    find(event: Event): Observable<Event[]> {
        return this.http.post<Event[]>(this.url + '/find', event);
    }

    findPage(event: Event, page: number): Observable<Pagination<Event>> {
        return this.http.post<Pagination<Event>>(this.url + '/find/' + page, event);
    }

    get(id: number): Observable<Event> {
        return this.http.get<Event>(this.url + '/get/' + id);
    }

    insert(event: Event): Observable<Event> {
        return this.http.post<Event>(this.url + '/insert', event);
    }

    update(event: Event): Observable<Event> {
        return this.http.post<Event>(this.url + '/update/' + event.id, event);
    }

    delete(event: Event): Observable<Event> {
        return this.http.post<Event>(this.url + '/delete/' + event.id, event);
    }

    generateQRCode(event: Event): Observable<Event> {
        return this.http.post<Event>(this.url + '/generateQRCode/' + event.id, event);
    }

    sendQRCode(code: string): Observable<AccountEvent> {
        return this.http.post<AccountEvent>(this.url + '/sendQRCode/' + code, null);
    }

    qrscan(code: string): Observable<AccountEvent> {
        return this.http.post<AccountEvent>(this.url + '/qrscan/' + code, null);
    }

    checkIn(code: string): Observable<AccountEvent> {
        return this.http.post<AccountEvent>(this.url + '/check-in/' + code, null);
    }

    checkOut(code: string): Observable<AccountEvent> {
        return this.http.post<AccountEvent>(this.url + '/check-out/' + code, null);
    }
}
