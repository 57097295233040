import {Injectable} from '@angular/core';
import {Observable, Subject} from 'rxjs';
import {filter} from 'rxjs/operators';

import {Alert, AlertType} from './alert.model';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';

@Injectable({providedIn: 'root'})
export class AlertService {
    private subject = new Subject<Alert>();

    constructor(private modalService: NgbModal) {
    }

    // enable subscribing to alerts observable
    onAlert(alertId?: string): Observable<Alert> {
        return this.subject
            .asObservable()
            .pipe(filter(x => x && x.alertId === alertId));
    }

    // convenience methods
    success(key: string, alertId?: string) {
        this.alert(new Alert({key: key, type: AlertType.Success, alertId}));
    }

    successMessage(message: string, alertId?: string) {
        this.alert(
            new Alert({message: message, type: AlertType.Success, alertId})
        );
    }

    error(key: string, alertId?: string) {
        this.alert(new Alert({key: key, type: AlertType.Error, alertId}));
    }

    errorMessage(message: string, alertId?: string) {
        this.alert(new Alert({message: message, type: AlertType.Error, alertId}));
    }

    info(key: string, alertId?: string) {
        this.alert(new Alert({key: key, type: AlertType.Info, alertId}));
    }

    infoMessage(message: string, alertId?: string) {
        this.alert(new Alert({message: message, type: AlertType.Info, alertId}));
    }

    warn(key: string, alertId?: string) {
        this.alert(new Alert({key: key, type: AlertType.Warning, alertId}));
    }

    warnMessage(message: string, alertId?: string) {
        this.alert(
            new Alert({message: message, type: AlertType.Warning, alertId})
        );
    }

    add(key: string, alertType: AlertType, alertId?: string) {
        this.alert(new Alert({key: key, type: alertType, alertId}));
    }

    addMessage(message: string, alertType: AlertType, alertId?: string) {
        this.alert(new Alert({message: message, type: alertType, alertId}));
    }

    // main alert method
    alert(alert: Alert) {
        this.subject.next(alert);
    }

    // clear alerts
    clear(alertId?: string) {
        this.subject.next(new Alert({alertId}));
    }
}
