export class ObjectCategoryBase {
    id: number;
    type: string;
    property: string;
    name: string;
    label: string;
    value: string;
    description: string;
    options: any;
    priority: number;
}

export class ObjectCategory extends ObjectCategoryBase {
    static newInstance(label: string, value: string, description: string, options: any): ObjectCategory {
        const objectCategory = new ObjectCategory();
        objectCategory.label = label;
        objectCategory.value = value;
        objectCategory.description = description;
        objectCategory.options = options;
        return objectCategory;
    }
}

export class ObjectCategoryInfo extends ObjectCategory {
    count: number;
}

export class StatusInfo {
    statusCount: ObjectCategoryInfo[];
    trashCount: ObjectCategoryInfo[];
}
