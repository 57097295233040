import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Account} from '@core/bo';
import {environment} from '@environments/environment';
import {saveAs} from 'file-saver';

@Injectable({providedIn: 'root'})
export class ExportService {
    public url = environment.apiUrl.account + '/export';

    constructor(public http: HttpClient) {
    }

    syncAccount(account: Account): void {
        this.http.post(this.url + '/syncAccount', account, {responseType: 'arraybuffer'})
            .subscribe(response => {
                const blob = new Blob(['\uFEFF', response], {type: 'text/csv; charset=utf-8'});
                saveAs(blob, 'account-updated.csv');
            });
    }
}
