import {ChangeDetectorRef, Component, Input, OnDestroy, OnInit,} from '@angular/core';
import {Subject, Subscription} from 'rxjs';

import {Alert, AlertType} from './alert.model';
import {AlertService} from './alert.service';
import {takeUntil} from 'rxjs/operators';

@Component({selector: 'app-alert', templateUrl: 'alert.component.html'})
export class AlertComponent implements OnInit, OnDestroy {
    @Input() id: string;

    alerts: Alert[] = [];
    subscription: Subscription;
    private readonly onDestroy = new Subject<void>();

    constructor(
        private alertService: AlertService,
        private ref: ChangeDetectorRef
    ) {
    }

    ngOnInit() {
        this.subscription = this.alertService
            .onAlert(this.id)
            .pipe(takeUntil(this.onDestroy))
            .subscribe(alert => {
                this.ref.markForCheck();
                if (!alert.key && !alert.message) {
                    // clear alerts when an empty alert is received
                    this.alerts = [];
                    return;
                }
                // add alert to array
                this.alertService.clear(this.id);
                this.alerts.push(alert);
            });
    }

    ngOnDestroy() {
        // unsubscribe to avoid memory leaks
        this.subscription.unsubscribe();
        this.onDestroy.next();
    }

    removeAlert(alert: Alert) {
        this.alerts = this.alerts.filter(x => x !== alert);
    }

    cssClass(alert: Alert) {
        if (!alert) {
            return;
        }

        // return css class based on alert type
        switch (alert.type) {
            case AlertType.Success:
                return 'alert pxr-25 alert-success';
            case AlertType.Error:
                return 'alert pxr-25 alert-danger';
            case AlertType.Info:
                return 'alert pxr-25 alert-info';
            case AlertType.Warning:
                return 'alert pxr-25 alert-warning';
        }
    }
}
