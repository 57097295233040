export class AccountStatusBase {
    id: number;
    accountId: number;
    status: number;
    workSchedule: number;
    startDate: Date;
    endDate: Date;
    duration: string;
    description: string;
    isTrash: boolean;
    createdBy: number;
    createdDate: Date;
    modifiedBy: number;
    modifiedDate: Date;
}

export class AccountStatus extends AccountStatusBase {
    createdName: string;
}
