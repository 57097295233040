import {AfterViewInit, Component, ElementRef, OnDestroy, QueryList, ViewChildren} from '@angular/core';
import {startWith, subscribeOn, takeUntil} from 'rxjs/operators';
import {asapScheduler, combineLatest, Subject} from 'rxjs';
import {IndexItem, IndexService} from '@core/services/index.service';

@Component({
    selector: 'app-index',
    styleUrls: ['index.component.scss'],
    templateUrl: 'index.component.html',
})
export class IndexComponent implements AfterViewInit, OnDestroy {
    activeIndex: number | any = null;
    private onDestroy = new Subject();
    public indexList: IndexItem[];
    @ViewChildren('tocItem') private items: QueryList<ElementRef>;

    constructor(private indexService: IndexService) {
    }

    ngAfterViewInit(): void {
        combineLatest([
            this.indexService.activeItemIndex.pipe(subscribeOn(asapScheduler)),
            this.items.changes.pipe(startWith(this.items))
        ])
            .pipe(takeUntil(this.onDestroy)).subscribe(([index, items]) => {
            this.activeIndex = index;
        })
        this.indexService.indexList
            .pipe(takeUntil(this.onDestroy))
            .subscribe(indexList => {
                this.indexList = indexList;
            });
    }

    scroll(event, id: string) {
        document.querySelector('#' + id).scrollIntoView({behavior: 'smooth', block: 'start'});
    }

    ngOnDestroy() {
        this.onDestroy.next();
    }
}
