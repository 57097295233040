import {Injectable} from '@angular/core';
import {ReplaySubject} from 'rxjs';
import {IndexScrollSpyService, ScrollSpyInfo} from '@core/services/index-scroll-spy.service';

export interface IndexItem {
    title: string,
    anchor: string
}

@Injectable({providedIn: 'root'})
export class IndexService {
    private scrollSpyInfo: ScrollSpyInfo;
    activeItemIndex = new ReplaySubject<number | null>(1);
    indexList = new ReplaySubject<IndexItem[]>(1);

    constructor(private scrollSpyService: IndexScrollSpyService) {
    }

    genIndex(docElement?: Element) {
        this.resetScrollSpyInfo();
        if (!docElement) {
            this.indexList.next([]);
        }
        const headings = this.findIndexHeadings(docElement);
        const tocList = headings.map(heading => {
            return {
                title: (heading.innerHTML || '').trim(),
                anchor: heading.id
            }
        });
        this.indexList.next(tocList);
        this.scrollSpyInfo = this.scrollSpyService.spyOn(headings);
        this.scrollSpyInfo.active.subscribe(
            item => this.activeItemIndex.next(item && item.index)
        );
    }

    private findIndexHeadings(docElement: Element): HTMLHeadingElement[] {
        const headings = querySelectorAll<HTMLHeadingElement>(docElement, 'h1,h2,h3');
        return headings;
    }

    private resetScrollSpyInfo() {
        if (this.scrollSpyInfo) {
            this.scrollSpyInfo.unspy();
            this.scrollSpyInfo = null;
        }

        this.activeItemIndex.next(null);
    }
}

// @ts-ignore
function querySelectorAll<E extends Element = Element>(parent: Element, selector: string): E[];
function querySelectorAll(parent: Element, selector: string) {
    // Wrap the `NodeList` as a regular `Array` to have access to array methods.
    // NOTE: IE11 does not even support some methods of `NodeList`, such as
    //       [NodeList#forEach()](https://developer.mozilla.org/en-US/docs/Web/API/NodeList/forEach).
    return Array.from(parent.querySelectorAll(selector));
}
