export class Order {
    property: string;
    ignoreCase: boolean;
    direction: string;
}

export class Sort {
    orders: Order[];
}

export class Pageable {
    pageNumber: number;
    size: number;
    pages: number[];
    sort: Sort;
}

export class Pagination<T> {
    content = new Array<T>();
    pageable = new Page();
    total = 0;
}

export class Page {
    size = 30;
    page = 0;
}
