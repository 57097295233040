import {Directive, ElementRef, EventEmitter, Input, OnInit, Output, Renderer2} from '@angular/core';
import {DragService} from './drag.service';

@Directive({
    selector: '[appDroppable]'
})
export class DroppableDirective implements OnInit {

    constructor(private elementRef: ElementRef, private renderer: Renderer2, private dragService: DragService) {
        // this.renderer.addClass(this.elementRef.nativeElement, 'app-droppable')
    }

    onDragEnter: any;
    onDragLeave: any;
    private onDragOver: any;
    private onDrop: any;

    public options: DroppableOptions = {
        zone: 'appZone'
    };

    @Input()
    set appDroppable(options: DroppableOptions) {
        if (options) {
            this.options = options;
        }
    }

    @Output() public onDroppableComplete: EventEmitter<DroppableEventObject> = new EventEmitter<DroppableEventObject>();

    ngOnInit(): void {
        this.dragService.addAvailableZone(this.options.zone, {
            begin: () => {
                // this.renderer.addClass(this.elementRef.nativeElement, 'js-app-droppable--target');
            },
            end: () => {
                // this.renderer.removeClass(this.elementRef.nativeElement, 'js-app-droppable--target');
            },
        });
        this.addOnDragEvents();
    }

    private addOnDragEvents() {
        this.onDragEnter = this.renderer.listen(
            this.elementRef.nativeElement,
            'dragenter',
            (event: DragEvent): void => {
                this.handleDragEnter(event);
            });
        this.onDragLeave = this.renderer.listen(
            this.elementRef.nativeElement,
            'dragleave',
            (event: DragEvent): void => {
                this.handleDragLeave(event);
            });
        this.onDragOver = this.renderer.listen(
            this.elementRef.nativeElement,
            'dragover',
            (event: DragEvent): void => {
                this.handleDragOver(event);
            }
        );
        this.onDrop = this.renderer.listen(
            this.elementRef.nativeElement,
            'drop',
            (event: DragEvent): void => {
                this.handleDrop(event);
            }
        );
    }

    private handleDragEnter(event: DragEvent) {
        if (this.dragService.accepts(this.options.zone)) {
            event.preventDefault();
            // this.renderer.addClass(event.target, 'js-app-droppable--zone');
        }
    }

    private handleDragLeave(event: DragEvent) {
        if (this.dragService.accepts(this.options.zone)) {
            // this.renderer.removeClass(event.target, 'js-app-droppable--zone');
        }
    }

    private handleDragOver(event: DragEvent) {
        if (this.dragService.accepts(this.options.zone)) {
            event.preventDefault();
        }

    }

    private handleDrop(event: DragEvent) {
        //  remove style
        this.dragService.removeHighLightedAvailableZones();
        // this.renderer.removeClass(event.target, 'js-app-droppable--zone');

        const transferData = JSON.parse(event.dataTransfer.getData('Text'));
        this.onDroppableComplete.emit({
            e: event,
            index: transferData.index,
            data: transferData,
            zone: this.options.zone
        });

    }
}

export interface DroppableOptions {
    data?: any;
    zone?: string;
}

// Droppable Event Object
export interface DroppableEventObject {
    e: any;
    index: any;
    data: any;
    zone: any;
}
