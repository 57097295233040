import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {ConfirmDialogComponent} from './confirm-dialog/confirm-dialog.component';
import {ErrorMessagePipe} from './error-message.pipe';
import {AlertModule} from './alert';
import {SpinnerModule} from './spinner';
import {HboxComponent} from './hbox/hbox.component';
import {DatatableComponent} from './datatable/datatable.component';
import {
    NgbCollapseModule,
    NgbDatepickerModule, NgbNavModule,
    NgbPaginationModule,
    NgbPopoverModule,
    NgbTimepickerModule
} from '@ng-bootstrap/ng-bootstrap';
import {RouterModule} from '@angular/router';
import {NgSelectModule} from '@ng-select/ng-select';
import {BreadcrumbComponent} from './breadcrumb/breadcrumb.component';
import {DraggableDirective} from './dnd/draggable.directive';
import {DroppableDirective} from './dnd/droppable.directive';
import {PriorityDialogComponent} from './priority-dialog/priority-dialog.component';
import {DragulaModule} from 'ng2-dragula';
import {DateTimePickerComponent} from './date-time-picker/date-time-picker.component';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {PaginationComponent} from '@shared/pagination/pagination.component';
import {DateTimeInputComponent} from './date-time-input/date-time-input.component';
import {IndexComponent} from '@shared/index/index.component';
import {ChipComponent} from '@shared/chip/chip.component';
import {IfRoleDirective} from '@shared/directives/if-role.directive';
import {FileUploadModule} from '@shared/file-upload/file-upload.module';

@NgModule({
    imports: [
        CommonModule,
        AlertModule,
        SpinnerModule,
        NgbCollapseModule,
        RouterModule,
        NgSelectModule,
        DragulaModule,
        FormsModule,
        NgbPopoverModule,
        NgbDatepickerModule,
        NgbTimepickerModule,
        NgbPaginationModule,
        NgbNavModule,
        ReactiveFormsModule,
        FileUploadModule,
    ],
    declarations: [
        DraggableDirective,
        DroppableDirective,
        BreadcrumbComponent,
        ConfirmDialogComponent,
        PaginationComponent,
        ErrorMessagePipe,
        HboxComponent,
        DatatableComponent,
        PriorityDialogComponent,
        DateTimePickerComponent,
        DateTimeInputComponent,
        IndexComponent,
        ChipComponent,
        IfRoleDirective
    ],
    exports: [
        HboxComponent,
        SpinnerModule,
        DatatableComponent,
        NgSelectModule,
        BreadcrumbComponent,
        DraggableDirective,
        DroppableDirective,
        PriorityDialogComponent,
        DateTimePickerComponent,
        PaginationComponent,
        DateTimeInputComponent,
        IndexComponent,
        ChipComponent,
        IfRoleDirective
    ],
    entryComponents: [
        ConfirmDialogComponent
    ]
})
export class SharedModule {
}
