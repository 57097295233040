import {HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from '@angular/common/http';
import {Observable, of} from 'rxjs';
import {Injectable} from '@angular/core';
import {catchError} from 'rxjs/operators';
import {Router} from '@angular/router';
import {ToastrService} from 'ngx-toastr';

// import {MatDialog} from '@angular/material';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
    constructor(private router: Router, private toasterService: ToastrService) {
    }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        return next.handle(request).pipe(
            catchError(error => {
                if (error.status === 401) {
                    // auto logout if 401 response returned from api
                    // redirect to the login route
                    // or show a modal
                    this.router.navigate(['/auth/login']);
                    // } else if (error.status === 403)
                    //     const dialogRef = this.dialog.open(ConfirmDialogComponent, {
                    //         data: error
                    //     });
                } else {
                    // const dialogRef = this.dialog.open(ConfirmDialogComponent, {
                    //     data: error.error
                    // });
                    // return throwError(error.error.message || error.statusText);
                    // return throwError(error);
                    this.toasterService.error(error.error.message || error.statusText);
                    return of(null);
                }
            })
        );
    }
}
