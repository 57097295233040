import {Account} from '@core/bo/account.model';
import {Event} from '@core/bo/event.model';

export class AccountEventBase {
    id: number;
    code: string;
    accountId: number;
    eventId: number;
    checkIn: Date;
    checkOut: Date;
    createdBy: number;
    createdDate: Date;
    modifiedBy: number;
    modifiedDate: Date;
    account: Account;
    event: Event;
}

export class AccountEvent extends AccountEventBase {
}
