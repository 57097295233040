import {Component, ContentChild, EventEmitter, Input, OnInit, Output, TemplateRef} from '@angular/core';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {AbstractControl, FormControl, FormGroup, Validators} from '@angular/forms';

@Component({
    selector: 'app-datatable',
    templateUrl: './datatable.component.html',
    styleUrls: ['./datatable.component.scss']
})
export class DatatableComponent implements OnInit {

    constructor(private modalService: NgbModal) {
    }

    /*table variable*/
    @Input() data;
    @Input() columns;
    @Input() actionsVisible = false;
    @Input() actionsWidth = 100;
    @ContentChild(TemplateRef) templateRef: TemplateRef<any>;
    @Output() sortByColumn = new EventEmitter<any>();

    /*create - update*/
    categoryDetail: any;

    /*table function*/
    tableCollapse = true;
    generalInfoCollapse = false;
    page = 4;

    get name() {
        return this.categoryDetail.get('name') as AbstractControl;
    }

    ngOnInit(): void {
        this.categoryDetail = new FormGroup({
            name: new FormControl('', [Validators.required])
        });
    }

    collapTable(e) {
        this.tableCollapse = !this.tableCollapse;
        if (this.tableCollapse) {
            e.currentTarget.children[0].classList.remove('link-arrow');
        } else {
            e.currentTarget.children[0].classList.add('link-arrow');
        }
    }

    sortBy(e, column) {
        if (column.sort === 'desc') {
            column.sort = 'asc';
        } else {
            column.sort = 'desc';
        }
        this.columns.forEach(item => {
            if (e.currentTarget.id !== item.prop) {
                item.sort = ''
            }
        });
        this.sortByColumn.emit({name: e.currentTarget.id, sortDirection: column.sort});
    }
}

interface TableColumn {
    prop: string;
    name: string;
    sort: string | null;
    width: string;
}
