import {Component, OnInit, ViewChild} from '@angular/core';
import {ToastContainerDirective, ToastrService} from 'ngx-toastr';
import {LoginService} from '@core/services';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
})
export class AppComponent implements OnInit {
    title = 'Hebela Admin UI';

    @ViewChild(ToastContainerDirective, {static: true})
    toastContainer: ToastContainerDirective;

    constructor(private toastrService: ToastrService,
                private loginService: LoginService) {
    }

    ngOnInit(): void {
        this.loginService.getLogged().subscribe(admin => {
        });
        this.toastrService.overlayContainer = this.toastContainer;
    }

}
