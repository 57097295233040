import {Component, EventEmitter, Input, Output} from '@angular/core';

@Component({
    selector: 'app-chip',
    templateUrl: 'chip.component.html',
    styleUrls: ['chip.component.scss']
})
export class ChipComponent {
    @Input() item;
    @Input() label;
    @Output() closeFunction = new EventEmitter<string>();

    constructor() {
    }


    close() {
        this.closeFunction.emit(this.item)
    }
}
