export class CountryBase {
    id: number;
    code: string;
    name: string;
    phoneCode: number;
    createdBy: number;
    createdDate: Date;
    modifiedBy: number;
    modifiedDate: Date;
}

export class Country extends CountryBase {
}
