import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {Pagination, StatusInfo, Organization} from '@core/bo';
import {environment} from '@environments/environment';

@Injectable({providedIn: 'root'})
export class OrganizationService {
    public url = environment.apiUrl.account + '/organization';

    constructor(public http: HttpClient) {
    }

    find(organization: Organization): Observable<Organization[]> {
        return this.http.post<Organization[]>(this.url + '/find', organization);
    }

    findPage(organization: Organization, page: number): Observable<Pagination<Organization>> {
        return this.http.post<Pagination<Organization>>(this.url + '/find/' + page, organization);
    }

    get(id: number): Observable<Organization> {
        return this.http.get<Organization>(this.url + '/get/' + id);
    }

    insert(organization: Organization): Observable<Organization> {
        return this.http.post<Organization>(this.url + '/insert', organization);
    }

    update(organization: Organization): Observable<Organization> {
        return this.http.post<Organization>(this.url + '/update/' + organization.id, organization);
    }

    delete(organization: Organization): Observable<Organization> {
        return this.http.post<Organization>(this.url + '/delete/' + organization.id, organization);
    }

    trash(organization: Organization): Observable<Organization> {
        return this.http.post<Organization>(this.url + '/trash/' + organization.id, organization);
    }

    restore(organization: Organization): Observable<Organization> {
        return this.http.post<Organization>(this.url + '/restore/' + organization.id, organization);
    }

    updateStatus(organization: Organization): Observable<Organization> {
        return this.http.post<Organization>(this.url + '/updateStatus/' + organization.id, organization);
    }

    statusInfo(): Observable<StatusInfo> {
        return this.http.get<StatusInfo>(this.url + '/statusInfo');
    }

    priority(organizationList: Organization[]) {
        return this.http.put(this.url + '/priority', organizationList);
    }
}
