import {Inject, Injectable, OnDestroy} from '@angular/core';
import {DOCUMENT} from '@angular/common';

@Injectable({providedIn: 'root'})
export class ScrollService implements OnDestroy {
    private _topOffset: number | null;

    constructor(@Inject(DOCUMENT) private document: any) {
    }

    ngOnDestroy(): void {
    }

    get topOffset() {
        if (!this._topOffset) {
            this._topOffset = 120;
        }
        return this._topOffset as number;
    }

}
