export class TemplateBase {
    id: number;
    name: string;
    sender: string;
    subject: string;
    content: string;
    createdBy: number;
    createdDate: Date;
    modifiedBy: number;
    modifiedDate: Date;
}

export class Template extends TemplateBase {
}
