import {Directive, ElementRef, Input, OnInit, Renderer2} from '@angular/core';
import {DragService} from './drag.service';

@Directive({
    selector: '[appDraggable]'
})
export class DraggableDirective implements OnInit {

    onDragStart: any;
    onDragEnd: any;
    options: DraggableOptions;

    // 2
    @Input()
    set appDraggable(options: DraggableOptions) {
        if (options) {
            this.options = options;
        }
    }

    ngOnInit(): void {
        this.addDragEvents();
    }

    constructor(private elementRef: ElementRef, private renderer: Renderer2, private dragService: DragService) {
        this.renderer.setProperty(this.elementRef.nativeElement, 'draggable', true);
        this.renderer.addClass(this.elementRef.nativeElement, 'app-draggable');
    }

    private addDragEvents(): void {
        this.onDragStart = this.renderer.listen(this.elementRef.nativeElement, 'dragstart',
            (event: DragEvent): void => {
                const target = event.target as HTMLElement;
                const index = Array.from(target.parentElement.children).indexOf(target)
                this.options.index = index;
                event.dataTransfer.setData('Text', JSON.stringify(this.options));
            });

        this.onDragEnd = this.renderer.listen(this.elementRef.nativeElement, 'dragend',
            (event: DragEvent): void => {
                this.dragService.removeHighLightedAvailableZones();
            }
        )
    }
}

interface DraggableOptions {
    index: any;
    data?: any
}
