import {Component, Input, OnInit} from '@angular/core';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';

@Component({
    selector: 'app-confirm-dialog',
    templateUrl: './confirm-dialog.component.html',
    styleUrls: ['./confirm-dialog.component.scss']
})
export class ConfirmDialogComponent implements OnInit {

    @Input() error;

    constructor(private activeModal: NgbActiveModal) {
    }

    ngOnInit() {
    }

    close(): void {
        this.activeModal.dismiss();
    }

    confirmOk() {
        this.activeModal.close();
    }
}

