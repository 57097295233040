import {Account} from '@core/bo/account.model';

export class LocationBase {
    id: number;
    countryId: number;
    countryStateId: number;
    name: string;
    address: string;
    hrSupportId: number;
    description: string;
    createdBy: number;
    createdDate: Date;
    modifiedBy: number;
    modifiedDate: Date;
}

export class Location extends LocationBase {
    hrSupport: Account;
}
