import {ModuleWithProviders, NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {BaseComponent} from '@views/layout/base/base.component';
import {ErrorPageComponent} from '@views/pages/error-page/error-page.component';
import {HomeComponent} from '@views/pages/home/home.component';

const routes: Routes = [
    {path: 'auth', loadChildren: () => import('./views/pages/auth/auth.module').then(m => m.AuthModule)},
    {
        path: '',
        component: BaseComponent,
        // canActivate: [AuthGuard],
        children: [
            {path: '', redirectTo: 'home', pathMatch: 'full'},
            {path: 'home', component: HomeComponent, pathMatch: 'full'},
            {
                path: 'admin',
                loadChildren: () => import('@views/pages/account/account.module').then(m => m.AccountModule),
                data: {breadcrumb: 'Quản lý người dùng'}
            },
            {
                path: 'organization',
                loadChildren: () => import('@views/pages/organization/organization.module').then(m => m.OrganizationModule),
                data: {breadcrumb: 'Quản lý đơn vị'}
            },
            {
                path: 'location',
                loadChildren: () => import('@views/pages/location/location.module').then(m => m.LocationModule),
                data: {breadcrumb: 'Quản lý địa điểm'}
            },
            {
                path: 'event',
                loadChildren: () => import('@views/pages/event/event.module').then(m => m.EventModule),
                data: {breadcrumb: 'Quản lý sự kiện'}
            },
            {
                path: 'template',
                loadChildren: () => import('@views/pages/template/template.module').then(m => m.TemplateModule),
                data: {breadcrumb: 'Quản lý mẫu nội dung'}
            },
            {
                path: 'profile',
                loadChildren: () => import('@views/pages/profile/profile.module').then(m => m.ProfileModule)
            }
        ]
    },
    {
        path: 'error',
        component: ErrorPageComponent,
        data: {
            type: 404,
            title: 'Page Not Found',
            desc: 'Oops!! The page you were looking for doesn\'t exist.'
        }
    },
    {
        path: 'error/:type',
        component: ErrorPageComponent
    },
    {path: '**', redirectTo: 'error', pathMatch: 'full'}
];

@NgModule({
    imports: [RouterModule.forRoot(routes, {
        onSameUrlNavigation: 'reload',
        scrollPositionRestoration: 'top', anchorScrolling: 'enabled', scrollOffset: [0, 80]
    })],
    exports: [RouterModule]
})
export class AppRoutingModule {
}

export const routing: ModuleWithProviders<AppRoutingModule> = RouterModule.forRoot(routes, {
    onSameUrlNavigation: 'reload'
});
