export class NavItem {
    id?: number;
    label?: string;
    icon?: string;
    link?: string;
    expanded?: boolean;
    subItems?: any;
    isTitle?: boolean;
    badge?: any;
    parentId?: number;
    roles?: string[];
}

export const NAV_ITEM_LIST: NavItem[] = [
    {
        label: 'User Management',
        isTitle: true,
        roles: ['USER', 'ACCOUNT', 'ASSIGN_ROLE', 'ORGANIZATION']
    },
    {
        label: 'Quản lý người dùng',
        icon: 'users',
        link: '/admin',
        roles: ['USER', 'ACCOUNT', 'ASSIGN_ROLE']
    },
    {
        label: 'Quản lý đơn vị',
        icon: 'briefcase',
        link: '/organization',
        roles: ['USER', 'ACCOUNT', 'ORGANIZATION']
    },
    /*{
        label: 'Quản lý nhóm',
        icon: 'briefcase',
        link: '/group',
        roles: ['USER', 'GROUP']
    },*/
    {
        label: 'Quản lý mẫu nội dung',
        icon: 'briefcase',
        link: '/template',
        roles: ['ACCOUNT', 'ORGANIZATION']
    },
    {
        label: 'Check In/Out',
        isTitle: true,
        roles: ['ACCOUNT', 'CHECK_INOUT', 'CHECK_INOUT_ADMIN']
    },
    {
        label: 'Sự kiện',
        icon: 'calendar',
        link: '/event',
        roles: ['CHECK_INOUT', 'CHECK_INOUT_ADMIN']
    },
    {
        label: 'Địa điểm',
        icon: 'map-pin',
        link: '/location',
        roles: ['ACCOUNT', 'CHECK_INOUT', 'CHECK_INOUT_ADMIN']
    },
    {
        label: 'Quét mã',
        icon: 'check-square',
        link: '/event/check-inout/0',
        roles: ['CHECK_INOUT_ADMIN']
    },
    {
        label: 'Cá nhân',
        isTitle: true,
        roles: ['CHECK_INOUT']
    },
    {
        label: 'Mã của tôi',
        icon: 'user',
        link: '/profile/event',
        roles: ['CHECK_INOUT']
    },
    {
        label: 'Check In/Out',
        icon: 'user-check',
        link: '/profile/check-inout',
        roles: ['CHECK_INOUT']
    }
];
