// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
    production: false,
    apiUrl: {
        account: '/api/account'
    },
    firebase: {
        apiKey: 'AIzaSyB8W3eo8YjzSMt3TG5TL_TU7PSVs7ONIoI',
        authDomain: 'hebela-store-dev.firebaseapp.com',
        projectId: 'hebela-store-dev',
        storageBucket: 'hebela-store-dev.appspot.com',
        messagingSenderId: '715244979009',
        appId: '1:715244979009:web:da27ee1644b2173449d7c0',
        measurementId: 'G-K8BYRMDH7X'
    }
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
