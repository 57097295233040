import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {Location, Pagination} from '@core/bo';
import {environment} from '@environments/environment';

@Injectable({providedIn: 'root'})
export class LocationService {
    public url = environment.apiUrl.account + '/location';

    constructor(public http: HttpClient) {
    }

    find(location: Location): Observable<Location[]> {
        return this.http.post<Location[]>(this.url + '/find', location);
    }

    findPage(location: Location, page: number): Observable<Pagination<Location>> {
        return this.http.post<Pagination<Location>>(this.url + '/find/' + page, location);
    }

    get(id: number): Observable<Location> {
        return this.http.get<Location>(this.url + '/get/' + id);
    }

    insert(location: Location): Observable<Location> {
        return this.http.post<Location>(this.url + '/insert', location);
    }

    update(location: Location): Observable<Location> {
        return this.http.post<Location>(this.url + '/update/' + location.id, location);
    }

    delete(location: Location): Observable<Location> {
        return this.http.post<Location>(this.url + '/delete/' + location.id, location);
    }
}
