import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {Template, Pagination} from '@core/bo';
import {environment} from '@environments/environment';

@Injectable({providedIn: 'root'})
export class TemplateService {
    public url = environment.apiUrl.account + '/template';

    constructor(public http: HttpClient) {
    }

    find(template: Template): Observable<Template[]> {
        return this.http.post<Template[]>(this.url + '/find', template);
    }

    findPage(template: Template, page: number): Observable<Pagination<Template>> {
        return this.http.post<Pagination<Template>>(this.url + '/find/' + page, template);
    }

    get(id: number): Observable<Template> {
        return this.http.get<Template>(this.url + '/get/' + id);
    }

    insert(template: Template): Observable<Template> {
        return this.http.post<Template>(this.url + '/insert', template);
    }

    update(template: Template): Observable<Template> {
        return this.http.post<Template>(this.url + '/update/' + template.id, template);
    }

    delete(template: Template): Observable<Template> {
        return this.http.post<Template>(this.url + '/delete/' + template.id, template);
    }
}
