import {Location} from '@core/bo/location.model';
import {AccountEvent} from '@core/bo/account-event.model';

export class EventBase {
    id: number;
    code: string;
    name: string;
    url: string;
    description: string;
    locationId: number;
    startTime: Date;
    endTime: Date;
    replyTo: string;
    createdBy: number;
    createdDate: Date;
    modifiedBy: number;
    modifiedDate: Date;
    location: Location;
}

export class Event extends EventBase {
    accountCount: number;
    accountCheckIn: number;
    accountCheckOut: number;
    accountEventList: AccountEvent[]
}
