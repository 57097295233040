import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {Account, ChangeOrg, Country, CountryState, ObjectCategory, Pagination, StatusInfo} from '@core/bo';
import {environment} from '@environments/environment';

@Injectable({providedIn: 'root'})
export class AccountV1Service {
    public url = environment.apiUrl.account + '/v1/account';

    constructor(public http: HttpClient) {
    }

    find(admin: Account): Observable<Account[]> {
        return this.http.post<Account[]>(this.url + '/find', admin);
    }

    findPage(admin: Account, page: number): Observable<Pagination<Account>> {
        return this.http.post<Pagination<Account>>(this.url + '/find/' + page, admin);
    }

    get(id: number): Observable<Account> {
        return this.http.get<Account>(this.url + '/get/' + id);
    }

    statusInfo(admin: Account): Observable<StatusInfo> {
        return this.http.post<StatusInfo>(this.url + '/statusInfo', admin);
    }

    findCountry(): Observable<Country[]> {
        return this.http.get<Country[]>(this.url + '/findCountry');
    }

    findCountryState(countryId: number): Observable<CountryState[]> {
        return this.http.get<CountryState[]>(this.url + '/findCountryState/' + (countryId ? countryId : ''));
    }

    findObjectCategory(objectCategory: ObjectCategory): Observable<ObjectCategory[]> {
        return this.http.post<ObjectCategory[]>(this.url + '/findObjectCategory', objectCategory);
    }

    mapObjectCategory(objectCategory: ObjectCategory): Observable<any> {
        return this.http.post<any>(this.url + '/mapObjectCategory', objectCategory);
    }

    findChangeOrg(id: number): Observable<ChangeOrg[]> {
        return this.http.get<ChangeOrg[]>(this.url + '/changeOrg/' + id);
    }
}
