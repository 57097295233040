import {Component, OnInit} from '@angular/core';
import {RouteConfigLoadEnd, RouteConfigLoadStart, Router} from '@angular/router';

@Component({
    selector: 'app-base',
    templateUrl: './base.component.html',
})
export class BaseComponent implements OnInit {

    isLoading: boolean;

    constructor(private router: Router) {

        // Spinner for lazyload modules
        router.events.forEach((event) => {
            if (event instanceof RouteConfigLoadStart) {
                this.isLoading = true;
            } else if (event instanceof RouteConfigLoadEnd) {
                this.isLoading = false;
            }
        });

    }

    ngOnInit(): void {
    }

}
