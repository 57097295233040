import {Component, Input, OnInit} from '@angular/core';

@Component({
    selector: 'app-hbox',
    templateUrl: './hbox.component.html',
    styleUrls: ['./hbox.component.scss']
})
export class HboxComponent implements OnInit {
    boxCollapse: boolean;
    @Input() anchor: string;
    @Input() title: string;
    @Input() isClose: boolean;

    constructor() {
    }

    ngOnInit(): void {
        if (this.isClose === true) {
            this.boxCollapse = true;
        } else {
            this.boxCollapse = false;
        }
    }

}
