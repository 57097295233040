import {Inject, Injectable, PLATFORM_ID} from '@angular/core';
import {isPlatformBrowser} from '@angular/common';
import {LocalStorageKeys} from '@core/bo/local-storage.model';

@Injectable({providedIn: 'root'})
export class LocalStorageService {
    private readonly _isBrowser: boolean;

    constructor(@Inject(PLATFORM_ID) platformId) {
        this._isBrowser = isPlatformBrowser(platformId);
    }

    setItem(key: LocalStorageKeys, val: string) {
        return this._isBrowser && localStorage.setItem(key, val);
    }

    getItem(key: LocalStorageKeys): string {
        return this._isBrowser && localStorage.getItem(key);
    }

    removeItem(key: LocalStorageKeys) {
        return this._isBrowser && localStorage.removeItem(key);
    }

    clear() {
        return this._isBrowser && localStorage.clear();
    }
}
