import {Pipe, PipeTransform} from '@angular/core';

export const errorMap = {
    'Bad credentials': 'Tài khoản hoặc mật khẩu không đúng',
    'No value present': 'Tài khoản hoặc mật khẩu không đúng',
    'Duplicate entry \'{0}\' for key \'{1}\'': 'Thông tin \'{1}\' đã tồn tại giá trị \'{0}\''
};

@Pipe({name: 'ErrorMessage', pure: false})
export class ErrorMessagePipe implements PipeTransform {
    transform(value, args?): Array<any> {
        const valueList = this.getValue(value);
        let errorMessage: string = errorMap[valueList[valueList.length - 1]];
        if (errorMessage && valueList.length > 1) {
            for (let i = 0; i < valueList.length - 1; i++) {
                errorMessage = errorMessage.replace('{' + i + '}', valueList[i]);
            }
        }
        return errorMessage ? errorMessage : value;
    }

    getValue(value): Array<string> {
        const valueList: Array<string> = new Array();
        let state = 0;
        let c0: string, c1: string, s = null, t = '';
        for (let i = 0; i < value.length; i++) {
            c1 = value.charAt(i);
            if (c1 === '\'') {
                if (state === 0) {
                    state = 1;
                    s = null;
                } else {
                    s = s == null ? c1 : (s + c1);
                }
            } else {
                if (state === 0) {
                    t += c1;
                } else {
                    if (c0 === '\'' && s != null) {
                        t += '\'{' + valueList.length + '}\'';
                        valueList.push(s.substr(0, s.length - 1));
                        state = 0;
                        s = null;
                        t += c1;
                    } else {
                        s = s == null ? c1 : (s + c1);
                    }
                }
            }
            c0 = c1;
        }
        if (s != null) {
            t += '\'{' + valueList.length + '}\'';
            valueList.push(s.substr(0, s.length - 1));
        }
        valueList.push(t);
        return valueList;
    }
}
