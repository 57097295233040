import {Component, Inject, OnInit, Renderer2} from '@angular/core';
import {DOCUMENT} from '@angular/common';
import {Router} from '@angular/router';
import {LoginService} from '@core/services';
import {Account} from '@core/bo';

@Component({
    selector: 'app-navbar',
    templateUrl: './navbar.component.html',
})
export class NavbarComponent implements OnInit {
    admin: Account;

    constructor(
        @Inject(DOCUMENT) private document: Document,
        private renderer: Renderer2,
        private router: Router,
        private loginService: LoginService
    ) {
    }

    ngOnInit(): void {
        this.loginService.getAdmin().subscribe(admin => {
            this.admin = admin;
        });
    }

    /**
     * Sidebar toggle on hamburger button click
     */
    toggleSidebar(e) {
        e.preventDefault();
        this.document.body.classList.toggle('sidebar-open');
    }

    /**
     * Logout
     */
    onLogout(e) {
        e.preventDefault();
        this.loginService.logout().subscribe(data => {
            this.router.navigateByUrl('/auth/login');
        });
    }

}
