import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {Account, Pagination, StatusInfo} from '@core/bo';
import {environment} from '@environments/environment';

@Injectable({providedIn: 'root'})
export class AccountService {
    public url = environment.apiUrl.account + '/account';

    constructor(public http: HttpClient) {
    }

    find(admin: Account): Observable<Account[]> {
        return this.http.post<Account[]>(this.url + '/find', admin);
    }

    findPage(admin: Account, page: number): Observable<Pagination<Account>> {
        return this.http.post<Pagination<Account>>(this.url + '/find/' + page, admin);
    }

    get(id: number): Observable<Account> {
        return this.http.get<Account>(this.url + '/get/' + id);
    }

    insert(admin: Account): Observable<Account> {
        return this.http.post<Account>(this.url + '/insert', admin);
    }

    update(admin: Account): Observable<Account> {
        return this.http.post<Account>(this.url + '/update/' + admin.id, admin);
    }

    delete(admin: Account): Observable<Account> {
        return this.http.post<Account>(this.url + '/delete/' + admin.id, admin);
    }

    trash(admin: Account): Observable<Account> {
        return this.http.post<Account>(this.url + '/trash/' + admin.id, admin);
    }

    restore(admin: Account): Observable<Account> {
        return this.http.post<Account>(this.url + '/restore/' + admin.id, admin);
    }

    updateStatus(admin: Account): Observable<Account> {
        return this.http.post<Account>(this.url + '/updateStatus/' + admin.id, admin);
    }

    changeOrg(account: Account): Observable<Account> {
        return this.http.post<Account>(this.url + '/changeOrg/' + account.id, account);
    }

    updated(admin: Account): Observable<Account> {
        return this.http.post<Account>(this.url + '/updated/' + admin.id, admin);
    }

    statusInfo(): Observable<StatusInfo> {
        return this.http.get<StatusInfo>(this.url + '/statusInfo');
    }

    setRole(admin: Account): Observable<Account> {
        return this.http.post<Account>(this.url + '/set-role/' + admin.id, admin);
    }

    resetPassword(id: number): Observable<any> {
        return this.http.get<any>(this.url + '/reset-password/' + id);
    }

    sendEmail(admin: Account): Observable<Account> {
        return this.http.post<Account>(this.url + '/sendEmail/' + admin.id, admin);
    }

    sendEmails(templateId: number, accountChosen: number[]): Observable<number[]> {
        return this.http.post<number[]>(this.url + '/sendEmails/' + templateId, accountChosen);
    }

    uploadAvatar(admin: Account, file: File): Observable<Account> {
        const formdata = new FormData();
        formdata.append('content', file);
        return this.http.post<Account>(`${this.url}/${admin.sortBy}/${admin.id}`, formdata);
    }

    code2fa(id: number): Observable<any> {
        return this.http.get<any>(this.url + '/code2fa/' + id);
    }
}
