export class ChangeOrgBase {
    id: number;
    accountId: number;
    orgIdOld: number;
    orgIdNew: number;
    leaderId: number;
    startDate: Date;
    isUpdated: boolean;
    createdBy: number;
    createdDate: Date;
    modifiedBy: number;
    modifiedDate: Date;
}

export class ChangeOrg extends ChangeOrgBase {
    nameOld: string;
    nameNew: string;
    createdName: number;
    leaderName: number;
}
