import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {AccountEvent, Pagination} from '@core/bo';
import {environment} from '@environments/environment';

@Injectable({providedIn: 'root'})
export class AccountEventService {
    public url = environment.apiUrl.account + '/account-event';

    constructor(public http: HttpClient) {
    }

    find(accountEvent: AccountEvent): Observable<AccountEvent[]> {
        return this.http.post<AccountEvent[]>(this.url + '/find', accountEvent);
    }

    findPage(accountEvent: AccountEvent, page: number): Observable<Pagination<AccountEvent>> {
        return this.http.post<Pagination<AccountEvent>>(this.url + '/find/' + page, accountEvent);
    }

    get(id: number): Observable<AccountEvent> {
        return this.http.get<AccountEvent>(this.url + '/get/' + id);
    }

    insert(accountEvent: AccountEvent): Observable<AccountEvent> {
        return this.http.post<AccountEvent>(this.url + '/insert', accountEvent);
    }

    update(accountEvent: AccountEvent): Observable<AccountEvent> {
        return this.http.post<AccountEvent>(this.url + '/update/' + accountEvent.id, accountEvent);
    }

    delete(accountEvent: AccountEvent): Observable<AccountEvent> {
        return this.http.post<AccountEvent>(this.url + '/delete/' + accountEvent.id, accountEvent);
    }

    my(): Observable<AccountEvent[]> {
        return this.http.post<AccountEvent[]>(this.url + '/my', null);
    }

    checkIn(accountEvent: AccountEvent): Observable<AccountEvent> {
        return this.http.post<AccountEvent>(this.url + '/check-in', accountEvent);
    }

    checkOut(accountEvent: AccountEvent): Observable<AccountEvent> {
        return this.http.post<AccountEvent>(this.url + '/check-out', accountEvent);
    }
}
