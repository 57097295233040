import {Component, Input, Output, EventEmitter} from '@angular/core';

@Component({
    selector: 'app-pagination',
    templateUrl: './pagination.component.html'
})
export class PaginationComponent {
    @Input()
    total: any;
    @Input()
    pageSize: any;
    @Output() pageChange = new EventEmitter<any>();

    constructor() {
    }

    private _page: number;

    get page() {
        return this._page;
    }

    @Input()
    set page(page: number) {
        this._page = page + 1;
    }

    emitPageChange(page: number) {
        const newPage = page - 1;
        this.pageChange.emit(newPage);
    }
}
