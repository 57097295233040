import {Component, EventEmitter, Input, Output} from '@angular/core';
import {DateTimeModel} from '@shared/date-time-picker/date-time.model';
import {NgbDatepicker} from '@ng-bootstrap/ng-bootstrap';

@Component({
    selector: 'app-date-time-input',
    templateUrl: './date-time-input.component.html',
})
export class DateTimeInputComponent {
    inputDatetimeFormat = 'dd/MM/yyyy HH:mm';
    public datetime: DateTimeModel = new DateTimeModel();
    public showTimePickerToggle = false;
    @Output() onChange = new EventEmitter<any>();

    private _dateString: string;

    get dateString(): string {
        return this._dateString;
    }

    @Input() set dateString(value: string) {
        this._dateString = value;
        this.datetime = Object.assign(
            this.datetime,
            DateTimeModel.fromLocalString(this.dateString)
        );
    };

    onDateChange($event: any, dp: NgbDatepicker) {
        const date = new DateTimeModel($event);
        this.datetime.year = date.year;
        this.datetime.month = date.month;
        this.datetime.day = date.day;
        this.setDateStringModel();
    }

    onInputChange($event: any) {
        const value = $event.target.value;
        const dt = DateTimeModel.fromLocalString(value);
        if (dt) {
            this.datetime = dt;
            this.setDateStringModel();
        }
    }

    onTimeChange(event: any) {
        this.datetime.hour = event.hour;
        this.datetime.minute = event.minute;
        this.datetime.second = event.second;
        this.setDateStringModel();
    }

    toggleDateTimeState($event: any) {
        this.showTimePickerToggle = !this.showTimePickerToggle;
        $event.stopPropagation();
    }

    private setDateStringModel() {
        this.dateString = this.datetime.toString();
        if (this.dateString !== null) {
            this.onChange.emit(this.dateString);
        }
    }
}
