import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {Organization, StatusInfo} from '@core/bo';
import {environment} from '@environments/environment';
import {HierarchyTree} from '@core/bo/hierarchy-tree.model';

@Injectable({providedIn: 'root'})
export class OrganizationV1Service {
    public url = environment.apiUrl.account + '/v1/organization';

    constructor(public http: HttpClient) {
    }

    treeFlat(org: Organization): Observable<HierarchyTree<Organization>[]> {
        return this.http.post<HierarchyTree<Organization>[]>(this.url + '/treeFlat', org);
    }

    statusInfo(): Observable<StatusInfo> {
        return this.http.get<StatusInfo>(this.url + '/statusInfo');
    }
}
