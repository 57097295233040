export class CountryStateBase {
    id: number;
    countryId: number;
    code: string;
    name: string;
    createdBy: number;
    createdDate: Date;
    modifiedBy: number;
    modifiedDate: Date;
}

export class CountryState extends CountryStateBase {
}
