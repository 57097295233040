import {BrowserModule} from '@angular/platform-browser';
import {NgModule} from '@angular/core';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';

import {AppRoutingModule} from './app-routing.module';


import {AppComponent} from './app.component';

import {HIGHLIGHT_OPTIONS} from 'ngx-highlightjs';
import {NgxDatatableModule} from '@swimlane/ngx-datatable';
import {HttpClientModule} from '@angular/common/http';
import {NgbCollapseModule} from '@ng-bootstrap/ng-bootstrap';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {ToastrModule} from 'ngx-toastr';
import {httpInterceptorProviders} from '@core/interceptors';
import {DragulaModule} from 'ng2-dragula';
import {ErrorPageComponent} from '@views/pages/error-page/error-page.component';
import {LayoutModule} from '@views/layout/layout.module';
import {SharedModule} from '@shared/shared.module';
import {HomeComponent} from '@views/pages/home/home.component';
import {TINYMCE_CONFIG} from '@core/utils/tinymce';

@NgModule({
    declarations: [
        AppComponent,
        ErrorPageComponent,
        HomeComponent
    ],
    imports: [
        BrowserModule,
        AppRoutingModule,
        BrowserAnimationsModule,
        LayoutModule,
        NgxDatatableModule,
        HttpClientModule,
        NgbCollapseModule,
        ReactiveFormsModule,
        FormsModule,
        ToastrModule.forRoot(),
        DragulaModule.forRoot(),
        SharedModule
    ],
    providers: [
        httpInterceptorProviders,
        {
            provide: HIGHLIGHT_OPTIONS, // https://www.npmjs.com/package/ngx-highlightjs
            useValue: {
                coreLibraryLoader: () => import('highlight.js/lib/core'),
                languages: {
                    xml: () => import('highlight.js/lib/languages/xml'),
                    typescript: () => import('highlight.js/lib/languages/typescript'),
                    scss: () => import('highlight.js/lib/languages/scss'),
                }
            }
        },
        {
            provide: TINYMCE_CONFIG,
            useValue: {
                width: '100%',
                height: 400,
                menubar: false,
                plugins: [
                    'advlist autolink lists link image charmap print preview anchor',
                    'searchreplace visualblocks code fullscreen',
                    'insertdatetime media table paste code help wordcount media'
                ],
                content_css: '/assets/css/tiny-content.css',
                font_css: 'https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;500;700;900&display=swap',
                entity_encoding: 'raw',
                remove_script_host: false,
                relative_urls: false,
                toolbar:
                    'undo redo | fontsizeselect | formatselect | bold italic backcolor | \
                    alignleft aligncenter alignright alignjustify | \
                    bullist numlist outdent indent | removeformat | table | image | source code | help | media',
                valid_children: '+body[style],+body[script]',
            }
        }
    ],
    exports: [],
    bootstrap: [AppComponent]
})
export class AppModule {
}
