import {Injectable} from '@angular/core';

@Injectable()
export class DragService {

    private availableZones: any = {}

    constructor() {
    }

    removeHighLightedAvailableZones() {
        this.availableZones.end();
    }

    addAvailableZone(zoneID: string, obj: any) {
        this.availableZones = obj
    }

    accepts(zone: string) {
        return true;
    }
}
