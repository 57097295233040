import {Inject, Injectable} from '@angular/core';
import {fromEvent, Observable, ReplaySubject, Subject} from 'rxjs';
import {auditTime, distinctUntilChanged, takeUntil} from 'rxjs/operators';
import {DOCUMENT} from '@angular/common';
import {ScrollService} from '@core/services/scroll.service';

export interface ScrollItem {
    element: Element;
    index: number;
}

export class ScrollSpiedElement implements ScrollItem {
    top = 0;

    constructor(public readonly element: Element, public readonly index: number) {
    }

    calculateTop(scrollTop: number, topOffset: number) {
        this.top = scrollTop + this.element.getBoundingClientRect().top - topOffset;
    }
}

export class ScrollSpiedElementGroup {
    private spiedElements: ScrollSpiedElement[];
    activeScrollItem: ReplaySubject<ScrollItem | null> = new ReplaySubject(1);

    constructor(elements: Element[]) {
        this.spiedElements = elements.map((elem, i) => new ScrollSpiedElement(elem, i));
    }

    onScroll(scrollTop: number, maxScrollTop: number) {
        let activeItem: ScrollItem | undefined;

        if (scrollTop + 1 >= maxScrollTop) {
            activeItem = this.spiedElements[0];
        } else {
            this.spiedElements.some(spiedElem => {
                if (spiedElem.top <= scrollTop) {
                    activeItem = spiedElem;
                    return true;
                }
                return false;
            });
        }
        this.activeScrollItem.next(activeItem || null);
    }
    calibrate(scrollTop: number, topOffset: number) {
        this.spiedElements.forEach(spiedElem => spiedElem.calculateTop(scrollTop, topOffset));
        this.spiedElements.sort((a, b) => b.top - a.top);   // Sort in descending `top` order.
    }
}

export interface ScrollSpyInfo {
    active: Observable<ScrollItem | null>;
    unspy: () => void;
}

@Injectable({providedIn: 'root'})
export class IndexScrollSpyService {
    private spiedElementGroups: ScrollSpiedElementGroup[] = [];
    private onStopListening = new Subject();
    private scrollEvents = fromEvent(window, 'scroll').pipe(auditTime(10), takeUntil(this.onStopListening));
    private resizeEvents = fromEvent(window, 'resize').pipe(auditTime(300), takeUntil(this.onStopListening));
    private lastMaxScrollTop: number;
    private lastContentHeight: number;
    constructor(@Inject(DOCUMENT) private doc: any, private scrollService: ScrollService) {
    }

    spyOn(elements: Element[]): ScrollSpyInfo {
        if (!this.spiedElementGroups.length) {
            this.resizeEvents.subscribe(() => this.onResize());
            this.scrollEvents.subscribe(() => this.onScroll());
            this.onResize();
        }
        this.scrollEvents.subscribe(() => this.onScroll());
        const spiedGroup = new ScrollSpiedElementGroup(elements);
        const scrollTop = this.getScrollTop();
        const maxScrollTop = this.lastMaxScrollTop;
        spiedGroup.onScroll(scrollTop, maxScrollTop);
        this.spiedElementGroups.push(spiedGroup);
        return {
            active: spiedGroup.activeScrollItem.asObservable().pipe(distinctUntilChanged()),
            unspy: () => this.unspy(spiedGroup)
        }
    }

    private getContentHeight() {
        return this.doc.body.scrollHeight || Number.MAX_SAFE_INTEGER;
    }

    private getViewportHeight() {
        return window.innerHeight || 0;
    }

    private getTopOffset() {
        return this.scrollService.topOffset + 50;
    }

    private onResize() {
        const contentHeight = this.getContentHeight();
        const viewportHeight = this.getViewportHeight();
        const scrollTop = this.getScrollTop();
        const topOffset = this.getTopOffset();

        this.lastContentHeight = contentHeight;
        this.lastMaxScrollTop = contentHeight - viewportHeight;

        this.spiedElementGroups.forEach(group => group.calibrate(scrollTop, topOffset));
    }

    private onScroll() {
        if (this.lastContentHeight !== this.getContentHeight()) {
            // Something has caused the scroll height to change.
            // (E.g. image downloaded, accordion expanded/collapsed etc.)
            this.onResize();
        }
        const scrollTop = this.getScrollTop();
        const maxScrollTop = this.lastMaxScrollTop;
        this.spiedElementGroups.forEach(group => group.onScroll(scrollTop, maxScrollTop));
    }

    private getScrollTop() {
        return window && window.pageYOffset || 0;
    }

    private unspy(spiedGroup: ScrollSpiedElementGroup) {
        spiedGroup.activeScrollItem.complete();
        this.spiedElementGroups = this.spiedElementGroups.filter(group => group !== spiedGroup);

        if (!this.spiedElementGroups.length) {
            this.onStopListening.next();
        }
    }
}
