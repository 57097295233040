import {Directive, Input, OnInit, TemplateRef, ViewContainerRef} from '@angular/core';
import {Account} from '@core/bo';
import {LoginService} from '@core/services';

@Directive({
    selector: '[appIfRole]'
})
export class IfRoleDirective implements OnInit {
    public context: Account;
    public roleList: string[] = [];
    public allow = true;

    constructor(private loginService: LoginService,
                private templateRef: TemplateRef<any>,
                private viewContainer: ViewContainerRef) {
        this.loginService.getAdmin().subscribe(context => {
            this.context = context;
        });
    }

    @Input() set appIfRole(role: string) {
        if (role.startsWith('!')) {
            this.allow = false;
            role = role.substring(1, role.length);
        }
        role.split(',').forEach(value => this.roleList.push(value.trim()));
    }

    ngOnInit(): void {
        if (this.context.authorities) {
            for (const authority of this.context.authorities) {
                if (authority.role === 'ROOT' || this.roleList.find(value => authority.role === value)) {
                    if (this.allow) {
                        this.viewContainer.createEmbeddedView(this.templateRef);
                    } else {
                        this.viewContainer.clear();
                    }
                    return;
                }
            }
        }
        if (this.allow) {
            this.viewContainer.clear();
        } else {
            this.viewContainer.createEmbeddedView(this.templateRef);
        }
    }
}
