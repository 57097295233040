import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {BehaviorSubject, Observable} from 'rxjs';
import {environment} from '@environments/environment';
import {Account} from '@core/bo';
import {map, shareReplay, tap} from 'rxjs/operators';

@Injectable({providedIn: 'root'})
export class LoginService {
    private _admin$ = new BehaviorSubject<Account>(new Account());
    public admin$ = this._admin$.pipe(shareReplay({bufferSize: 1, refCount: true}));

    constructor(public http: HttpClient) {
    }

    private _setAdmin(admin: Account, isLogged: boolean = true): Account {
        admin.isLogged = isLogged;
        admin.isRoot = admin.authorities && !!admin.authorities.find(x => x.role === 'ROOT');
        this._admin$.next({...this._admin$.value, ...admin});
        return admin;
    }

    getAdmin(): Observable<Account> {
        return this.admin$;
    }

    login(username, password): Observable<any> {
        const formData = new FormData();
        formData.append('username', username);
        formData.append('password', password);
        return this.http.post<any>(environment.apiUrl.account + '/v1/auth/login', formData).pipe(map(
            loggedAdmin => this._setAdmin(loggedAdmin))
        );
    }

    logout(): Observable<any> {
        return this.http.get<any>(environment.apiUrl.account + '/v1/auth/logout').pipe(map(
            admin => this._setAdmin({} as Account, false))
        );
    }

    getLogged(): Observable<Account> {
        return this.http.get<Account>(environment.apiUrl.account + '/v1/auth/getLogged').pipe(map(
            loggedAdmin => this._setAdmin(loggedAdmin))
        );
    }

    loginSocial(account: Account): Observable<Account> {
        const formData = new FormData();
        formData.append('idToken', account.idToken);
        return this.http.post<Account>(environment.apiUrl.account + '/v1/auth/loginSocial', formData).pipe(tap(
            loggedAccount => {
                if (loggedAccount) {
                    this._setAdmin({...loggedAccount, isLogged: true});
                }
            })
        );
    }

    checkRole(context: Account, account: Account, role: string): boolean {
        if (context.isRoot) {
            return true;
        }
        if (context.orgIdGrantList) {
            if (account.org) {
                const roleList = [];
                role.split(',').forEach(value => roleList.push(value.trim()));
                if (context.authorities) {
                    for (const authority of context.authorities) {
                        if (roleList.find(value => authority.role === value)) {
                            if (context.orgIdGrantList.indexOf(account.org.id) !== -1) {
                                return true;
                            }
                        }
                    }
                }
            } else {
                return true;
            }
        }
        return false;
    }
}
