import {Component, Input} from '@angular/core';

@Component({
    selector: 'app-spinner',
    templateUrl: './spinner.component.html',
})
export class SpinnerComponent {
    @Input()
    fullScreen = false;

    @Input()
    show = false;

    @Input()
    size = 'md';

    @Input()
    loadingText = 'Loading';
}
