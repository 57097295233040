import {Component, Input, OnInit} from '@angular/core';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import {PriorityBase} from '@core/obj';

@Component({
    selector: 'app-priority-dialog',
    templateUrl: './priority-dialog.component.html',
    styleUrls: ['./priority-dialog.component.scss']
})
export class PriorityDialogComponent implements OnInit {
    @Input() priorityList: PriorityBase[];

    constructor(public activeModal: NgbActiveModal) {
    }

    ngOnInit(): void {
    }

    updatePriority() {
        const priorityList = this.priorityList.map((item, index) => ({...item, priority: index + 1}));
        this.activeModal.close(priorityList);
    }

    close() {
        this.activeModal.dismiss();
    }
}
